.dashBoardForm {
    margin-top: 3vh;
}
.header-style  {
    background-color: #BFC9CA;
    font-size: 2vh;
    border-style: groove;
    display: block;

}
.header-style-level1  {
    background-color: #AEB6BF;
    font-size: 2vh;
}
.formSelect{
    overflow-x: scroll;
    height: 4vh;
    width: 22vh;
    text-align: center ;
}
.formInput {
    min-width: 50vh;
    text-align: center;
    height: 4vh
}
.sectionTitle{
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
}

.audit-result-display-container .eds-tab-group {
    margin-bottom: 16px;
}
.mainNavigation {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 70px;
}
.eds-app-container {
    padding: 20px 56px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.table-layout .eds-grid {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.table-layout .eds-table {
    width: 100%;
}
.table-layout .eds-table-heading {
    padding: var(--eds-space-8);
    color: black;
    font-weight: bolder;
    min-width: 80px;
}
.table-layout .eds-table-heading:first-of-type {
    padding-left: var(--eds-space-16);
}
.table-layout .eds-table-cell {
    padding: var(--eds-space-8);
}
.table-layout .eds-table-cell.col-text-align-right span{
    text-align: right;
}
.table-layout .eds-table-cell:first-of-type {
    padding-left: var(--eds-space-16);
}

.grid-container {
    display: grid;
    overflow: auto;
    width: 100%;
}

.eds-vertical-navbar__strip ul {
    overflow: auto;
}
.eds-scrim .eds-modal__content {
    overflow: auto !important;
}

.orders-list-title, .order-details-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-bottom: 32px;
}

.orders-list-title h2, .order-details-title h2{
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: bold;
    line-height: initial;
}

.order-payload-modal .eds-modal {
    width: 80%;
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    width: 100%;
}

.pagination .pages {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

@media screen and (min-height: 10px) and (max-height: 4000px) {
    .dashboardContainer {
        overflow-x: scroll;
        width: 153vh;
        border-style: groove;
        border-radius: 1vh;
        height: 10vh;
        margin-top: 3vh;
        margin-left: 10vh;
        margin-bottom: 2vh
    }
    .dashboardGrid {
        border-style: groove;
		width: 153vh;
        margin-left: 10vh;
        height: 70vh;
        overflow: scroll;
        background-color: light
    }
    .css-18lolh3-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
        overflow: scroll;
    }

}

@media screen and (min-height: 10px) and (max-height: 4000px) {
    .dashboardContainer {
        width: 85%;
        border-style: groove;
        border-radius: 1vh;
        height: 15vh;
        margin-left: 25vh;
        margin-right: 15vh;
        margin-top: 5vh;
        padding-top: 2.5vh;
        margin-bottom: 2vh
    }
    .dashboardGrid {
        width: 85%;
        margin-left: 25vh;
        margin-right: 15vh;
        height: 100vh;
        overflow-x: scroll;
        background-color: light
    }
    .css-18lolh3-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
        font-size: medium;
    }
    .formSelect{
        height: 7vh;
        width: 40vh;
    }
    .dashBoardForm {
        margin-top: 1vh;
        padding-left: 35vh;
    }
    .formInput {
        height: 7vh;
        width: 60vh;
    }
    .paginationCaretLeft {
        vertical-align: sub;
        margin-left: 48px;
    }
    .paginationCaretRight {
        vertical-align: sub;
        margin-left: 36px;
        margin-right: 48px;
    }
    .pageStyle{
        cursor: pointer;
        color: #757575;
    }
    .paginationUnderlineStyle {
        border-bottom: 2px solid;
        color: var(--eds-color-text-default);
    }
    .selectRows{
        margin-left: 3px;
        padding: 7px 4px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 60px;
    }
    .table-layout {
        border-radius: 1px;
        scrollbar-width: 1px;
        width: 100%;
    }
}