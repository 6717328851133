.p-32 { padding: 32px; }
.p-0 { padding: 0px; }
.p-16 { padding: 16px !important; }
.pb-0 { padding-bottom: 0px; }
.mb-16 { margin-bottom: 16px; }
.mb-32 { margin-bottom: 32px; }
.mt-16 { margin-top: 16px; }
.d-flex { display: flex; }

.br-0 {
	border-radius: 0px !important;
}

.flex-direction-column {
	flex-direction: column;
}
.flex-direction-row-reverse {
  flex-direction: row-reverse;
}
.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.justify-centent-center {
  justify-content: center;
}

.flex-direction-column {
	flex-direction: column;
}

.table-header-min-width-auto table tr th{
	min-width: auto !important;
}

.break-word * {
	word-break: break-word;
}

.h-300 {
	height: 300px;
	overflow: auto;
}

.h-250 {
  height: 250px;
  overflow: auto;
}

.justify-content-evenly {
  justify-content: evenly;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.align-items-center {
  align-items: center;
}

.negative {
  color: red;
}

.positive {
  color: green;
}

.mainContainer {
  display: flex;
  height: 100vh;
  width: 100vw;
  position: absolute;
  overflow: hidden;
}

.osd-menu {
  gap: 30px;
  padding: 0px 10px;
}
.app-global-region h2 {
  color: #ffffff;
  font-weight: bold;
  font-size: 100%;
}

.app-global-region .eds-menu{
  /* width: 100%;
  min-width: 190px; */
  overflow: hidden;
  top: 5px !important;
  left: -25px !important;
}
.app-global-region .eds-menu-item div {
  text-align: left;
}

.eds-grid .grid-item-value, h3.grid-component-title, .grid-component-title {
  font-size: 14px;
  font-weight: bold;
}

[data-popper-placement="right"]:after {
  content: ' ';
  width: 0px;
  height: 0px;
  left: 2px;
}

.VerticalNavbar {
  border: 0px;
  margin: 0px;
  max-height: calc(100vh - 0px);
  min-height: auto;
}

.VerticalNavbar .eds-vertical-navbar__strip {
  padding: 0px;
}

.VerticalNavbar .eds-vertical-navbar__strip ul {
  overflow: auto;
  padding: 0px;
}

.appContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  /* background-image: linear-gradient(rgb(245, 245, 245), rgb(243, 243, 243), rgb(220, 220, 220)); */
}

h1.eds-app-header__name,
.eds-app-header .app-header-region span {
  font-weight: bold !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 100% !important;
}
h1.eds-app-header__name,
.eds-app-header .app-header-region {
  margin: 0;
}

.poNumberLevel1:hover {
  cursor: pointer;
}

.order-by-type-tooltip {
  top: 2px;
  right: 10px;
}

.metricsDashboardLevelZeroRow:hover {
  cursor: pointer;
  background-image: linear-gradient(rgb(211, 208, 207), rgb(203, 196, 196));

}

/* .eds-table .eds-table-heading {
  min-width: auto;
} */

.textfield-multiple {
  position: relative;
  z-index: 10;
}

.textfield-multiple .add-btn {
  position: absolute;
  right: 10px;
  top: 32px;
}

.textfield-values-multiple {
  position: absolute;
  background: #fff;
  width: 100%;
  padding: 2px 8px;
  max-height: 130px;
  overflow: auto;
  visibility: hidden;
}

.textfield-multiple:hover .textfield-values-multiple {
  visibility: visible;
}

.textfield-values-multiple li {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.textfield-values-multiple li:last-child {
  border-bottom: 0px;
}

.textfield-values-multiple .remove-btn {
  margin-right: 14px;
}

.waiting {
  position: absolute;
  background: #ffffffcc;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 13;
  align-items: center;
}

.qualtrics-survey-container {
  position: fixed;
  top: 50%;
  right: 0px;
  height: 640px;
  width: 400px;
  background: #999;
  margin-top: -320px;
  -webkit-transition: right 0.5s ease-in-out;
  -moz-transition: right 0.5s ease-in-out;
  -o-transition: right 0.5s ease-in-out;
  transition: right 0.5s ease-in-out;
}

.qualtrics-survey-container.qualtrics-survey-container-hide{
  right: -400px;
}

.datetime-picker .react-datepicker-wrapper div input {
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  font: var(--eds-type-body-2);
  outline: none;
  width: 100%;
  padding: 14px 16px;
  color: var(--eds-color-black);
  background-color: var(--eds-color-white);
  border: 1px solid var(--eds-color-grey-2);
  border-radius: var(--eds-radii-4);
}

.qualtrics-survey-container .qualtrics-survey-wrapper{
  position: relative;
  width: 100%;
  height: 100%;
}

.qualtrics-survey-container .qualtrics-survey-button {
  transform: rotate(270deg);
  position: absolute;
  top: 50%;
  left: -105px;
  margin-top: -24px;
  border-radius: 2px;
}

.qualtrics-survey-container .qualtrics-survey-frame {
  width: 100%;
  height: 100%;
  border: 1px solid grey;
}
.eds-grid .eds-card {
  border: 1px solid #ccc;
}
.datetime-picker {
  z-index: 20;
}
.datetime-picker input {
  text-align: left !important;
}
.search-card .eds-grid {
  gap: 0px 16px;
}
.order-reprocess-buttons {
  background: #fff;
  bottom: 0;
  box-shadow: 0 -1px 3px #ccc;
  left: 0;
  padding: 8px 81px;
  position: fixed;
  width: 100%;
}